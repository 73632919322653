import React from "react";
import { Link } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { cn } from "../lib/helpers";
import Container from "../components/container";

import * as styles from "../styles/components/short-page.module.css";

const ThankYouPage = () => (
  <Layout>
    <Seo title="Thank You" />
    <div className={styles.root}>
      <Container>
        <div className="grid">
          <div className="col-12 col-md-6">
            <h1>Contact Us</h1>
          </div>
          <div className="col-12 col-md-6">
            <div className="entry-content content-xl">
              <p className="h1">Thanks for contacting us! The form has been successfully submitted, and we will get in touch with you shortly. Return to our <Link to="/">homepage</Link>.</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </Layout>
);

export default ThankYouPage;